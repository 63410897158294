import axios from "axios";
import ReceiptRequest from "../models/ReceiptRequest";
import ReceiptResponse from "../models/ReceiptResponse";

const API_BASE_URL = "https://pos-api-sandbox.fiskaltrust.cloud";
const RECEIPT_BASE_URL = "https://receipts-sandbox.fiskaltrust.cloud";

export default class PosService {
    static async signReceipt(receiptRequest: ReceiptRequest, headers: any) {
        try {
            const response = await axios.post(`${API_BASE_URL}/v0/sign`, receiptRequest, { headers });
            var json = await response.data;
            await delay(1000);
            let result = await axios.post(`${API_BASE_URL}/v0/response`, json, { headers });
            while (result.status !== 200) {
                result = await axios.post(`${API_BASE_URL}/v0/response`, json, { headers });
                await delay(1000);
            }
            return result.data;
        } catch (error) {
            console.error("Error in signing:", error);
            throw error;
        }
    }

    static printReceipt = async (request: ReceiptRequest, receiptResponse: ReceiptResponse, headers: any) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/v0/print`, {
                request: request,
                response: receiptResponse
            }, { headers });
            return response.data; // UID for printing
        } catch (error) {
            console.error("Error in printing:", error);
            throw error;
        }
    };

    static sendReceiptEmail = async (recipient: string, queueId: string, queueItemId: string, headers: any) => {
        const request = JSON.stringify({"recipient": recipient});
        const response = await axios.post(`${RECEIPT_BASE_URL}/v0/${queueId}/${queueItemId}/email`, request, { headers });
        console.log(response.data);
        return response.data;
    }
}

function delay(milliseconds: number) {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
}
