import React, { useState } from 'react';
import './App.css';
import Products from './components/Products';
import { useLocalStorage } from '@uidotdev/usehooks';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [version] = useState(0);
  const [cashboxid] = useLocalStorage("cashboxid", "683ac0a5-9ac2-4834-8bf0-6e1cfe5dfc0b");
  const [accesstoken] = useLocalStorage("accesstoken", "BOJq3dbao0+Jt8O5Fc8HNi5rGJiIMWybideZAOrmn/gYHCSBEWwsaUnW9Zptns60WL92F5nK0v+I7SWZNXzINY8=");
  const [terminalid] = useLocalStorage("terminalid", "smartschankdemoterminal1");
  const [possystemid] = useLocalStorage("possystemid", "83c70aa8-87ef-4fa2-a378-9332a01abcce");
  
  (BigInt.prototype as any).toJSON = function () {
    return this.toString();
  };

  return (
    <div>
      <Products
        accesstoken={accesstoken}
        cashboxid={cashboxid}
        possystemid={possystemid}
        terminalid={terminalid}
        key={version} />
    </div>
  );
}

export default App;
