import { Product } from "../models/Product";

export default class StaticData {
    static products: Product[] = [
        { ProductName: "Espresso", Price: 2.50, Image: { Src: "espresso.svg", Height: "4vh", Width: "6vh", PaddingTop: "5vh" } },
        { ProductName: "Kaffee", Price: 3.00, Image: { Src: "verlangerter.svg", Height: "5vh", Width: "9vh", PaddingTop: "4vh" } },
        { ProductName: "Caffe Latte", Price: 3.50, Image: { Src: "latte.svg", Height: "8vh", Width: "4vh", PaddingTop: "2vh" } },
        { ProductName: "Tee", Price: 2.00, Image: { Src: "tea.svg", Height: "5vh", Width: "8vh", PaddingTop: "5vh" } },
        { ProductName: "Cappuccino", Price: 3.00, Image: { Src: "cappucino.svg", Height: "7vh", Width: "9vh",  PaddingTop: "4vh"} },
        { ProductName: "Bier", Price: 3.50, Image: { Src: "beer.svg", Height: "vh", Width: "5vh", PaddingTop: "4vh" } }
    ];
}