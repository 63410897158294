import { useLocalStorage } from '@uidotdev/usehooks';
import { useState } from 'react';
import { Button, Col, Container, Form, Row, Image } from 'react-bootstrap';
import { Product } from '../models/Product';
import PosService from '../services/PosService';
import ReceiptRequest from '../models/ReceiptRequest';
import StaticData from '../static/StaticData';
import isEmail from 'validator/lib/isEmail';
import Loader from './Loader';

export type PosSettingProps = {
    cashboxid: string;
    accesstoken: string;
    terminalid: string;
    possystemid: string;
}

const RECEIPT_URL = "https://receipts-sandbox.fiskaltrust.cloud/v0";
const shareAppId = 'e0b0a479-b26a-47a2-af5e-29bce1b15739'
const shareAppSecret = '5e7d5449-7f83-48f8-996c-2d41e200a90c'

const Products = (settings: PosSettingProps) => {

    let splitCounter = 0;
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useLocalStorage("email", "");
    const [isValidEmail, setIsValidEmail] = useState(isEmail(email));
    const [receiptCounter, setReceiptCounter] = useLocalStorage("receiptcounter", 1);
    const [sessionStart] = useLocalStorage("session_start", new Date());
    const [cbUser] = useState<string>("Chef");
    const [cbArea] = useState<string>("0");
    const [cbCustomer] = useState<string>();
    const [ftReceiptCase] = useState<bigint>(BigInt(0x003));


    const receiptRequest: ReceiptRequest = {
        ftCashBoxId: settings.cashboxid,
        cbTerminalID: settings.terminalid,
        ftPosSystemId: settings.possystemid,
        cbReceiptReference: `${receiptCounter}-${new Date(sessionStart).getTime()}`,
        cbArea: cbArea,
        cbUser: cbUser,
        cbCustomer: cbCustomer,
        ftReceiptCase: BigInt(BigInt(0x4154000000000000) | ftReceiptCase),
        cbChargeItems: [],
        cbPayItems: [],
        cbReceiptAmount: 0,
    }

    const performSign = async (product: Product) => {
        setIsLoading(true);
        const headers = {
            cashboxid: settings.cashboxid,
            accesstoken: settings.accesstoken,
        };
        receiptRequest.cbReceiptMoment = new Date();
        receiptRequest.cbChargeItems = [{
            Quantity: 1,
            Description: product.ProductName,
            Amount: product.Price,
            VATRate: 19.00,
            Moment: new Date(),
            Position: 1,
            ftChargeItemCase: BigInt(0x4445000000000011),
            VATAmount: product.Price * 0.19
        }];
        receiptRequest.cbPayItems = [{
            Amount: product.Price,
            Description: "Bar",
            Quantity: 1,
            Moment: new Date(),
            Position: 1,
            ftPayItemCase: BigInt(0x4445000000000001),
        }];
        receiptRequest.cbReceiptAmount = receiptRequest.cbChargeItems.reduce((total, item) => total + item.Amount, 0);

        const signResponse = await PosService.signReceipt(receiptRequest, headers);
        setReceiptCounter(receiptCounter + 1);
        await PosService.printReceipt(receiptRequest, signResponse, headers)

        const mailHeaders = {
            Accept: "application/json",
            "Content-Type": "application/json",
            applicationid: shareAppId,
            applicationsecret: shareAppSecret
        };
        await PosService.sendReceiptEmail(email, signResponse.ftQueueID, signResponse.ftQueueItemID, mailHeaders);
        window.location.href = `${RECEIPT_URL}/${signResponse.ftQueueID}/${signResponse.ftQueueItemID}`;
        //wait some till url is redirected
        await timeout(2000);
        setIsLoading(false);
    };

    function timeout(delay: number) {
        return new Promise( res => setTimeout(res, delay) );
    }

    return (
        <>
            {
                isLoading ?
                    <div style={{ paddingTop: "35vh" }}>
                        <Loader />
                    </div>
                    :
                    <Container style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                        <Row>
                            <Col xs={12} className='text-center'>
                                <Image src="ft-logo.svg" alt="logo" style={{ marginTop: "1vh", height: "7vh" }} />
                            </Col>
                            <Col xs={12} className='text-center' style={{ marginTop: "2vh" }}>
                                <p style={{ marginBottom: "0px" }}>Um mit der Bestellung fortzufahren, brauchen wir Ihre E-Mail.</p>
                            </Col>
                            <Col xs={12}>
                                <Form style={{ marginTop: "1vh" }}>
                                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                                        <Form.Control
                                            type='email'
                                            placeholder='Email'
                                            value={email}
                                            onChange={(e) => {
                                                setIsValidEmail(isEmail(e.target.value));
                                                setEmail(e.target.value);
                                            }} />
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col xs={12} className='text-center'>
                                <h1 style={{marginTop: "2vh"}}>Wählen Sie ein Getränk!</h1>
                            </Col>

                            {
                                StaticData.products.map((product, index) => {
                                    splitCounter++;
                                    return (
                                        <>
                                            <Col key={index} xs={6} className={`text-center ${splitCounter%2 === 0 ? "pl-4": "pr-4"}`}>
                                                <div className="d-grid gap-2">
                                                    <Button 
                                                    className={`item-button 
                                                    ${isValidEmail ? "" : "disabled"}`}
                                                        style={{ paddingTop: product.Image.PaddingTop }}
                                                        onClick={() => performSign(product)}>
                                                        <Image src={product.Image.Src} style={{height: product.Image.Height, width: product.Image.Width}} />
                                                        <p style={{ marginTop: "1vh" }} className='item-text'>{product.ProductName}</p>
                                                    </Button>
                                                </div>
                                            </Col>
                                            {splitCounter % 2 === 0 && <div style={{ height: "1vh" }}></div>}
                                        </>
                                    );
                                })}
                        </Row>
                    </Container>
            }
        </>
    );
};

export default Products;