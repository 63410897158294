import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import './Loader.css'

const Loader: React.FC = () => {
    return (
        <Container style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <Row>
                <Col xs={12} className='text-center'>
                    <Image src='blinking-tree.gif' style={{height: "10vh"}}></Image>
                    <p className='title'>Wird geladen...</p>
                    <p className='content'>Mit Ihrer Entscheidung haben Sie dazu beigetragen Resourcen zum Schutz der Umwelt einzusparen.</p>
                </Col>
            </Row>

        </Container>
    );
};

export default Loader;